import { memo, useState } from 'react';

import AccordionWithSelect, {
  AccordionWithSelectProps,
} from '@components/Accordion/AccordionWithSelect';
import { SALARY_RANGE_LIST } from '@configs/onboarding.personalDetails.config';
import { Item } from '@libs/types';
import { incomeConverter } from '@utils/general';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: [number, number];
  updateGlobalStore: (key, value) => void;
}

const SalaryRange = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [value, setValue] = useState(() =>
    incomeConverter({
      incomeFrom: savedValue[0],
      incomeTo: savedValue[1],
    })
  );

  const onItemSelect = (item: Item) => {
    setValue(item.value);
    console.log('Hello world', item);
    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithSelectProps = {
    list: SALARY_RANGE_LIST,
    title: 'Salary',
    placeholder: 'Eg: 12-15 Lpa',
    selectedItem: value,
    value: value,
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  return <AccordionWithSelect {...props} />;
};

export default memo(SalaryRange);
